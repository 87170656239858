import React, { useState } from "react";
import "../../App.css";
import { Form, Button, Row, Col, Container, Card } from "react-bootstrap";
import emailjs from "emailjs-com";
const data = {
  name: "",
  email: "",
  mobile: "",
  message: "",
};
export default function Contact() {
  const [values, setValues] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [errMessage, setErrMessage] = useState("");
  const [message, setMessage] = useState(false);
  const [err, setErr] = useState(false);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log(values);
    e.preventDefault();
    const message = await findFormErrors();
    console.log(message);
    if (message !== "") {
      setErrMessage(message);
      setMessage(true);
      setErr(true);
    } else {
      emailjs
        .send(
          process.env.REACT_APP_MAILJS_SERVICE,
          process.env.REACT_APP_MAILJS_TEMPLATE,
          values,
          process.env.REACT_APP_MAILJS_USER
        )
        .then(() => {
          setMessage(true);
          setErr(false);
          setValues(data);
          setErrMessage("");
        })
        .catch((e) => {
          setErrMessage("Uh-Oh Form not submitted please try again", e.message);
          setErr(true);
        });
    }
  };
  const findFormErrors = () => {
    const { name, email, mobile, message } = values;
    var re = /\S+@\S+\.\S+/;
    var pattern = new RegExp(/^[0-9\b]+$/);
    // name errors
    if (
      !name ||
      name === "" ||
      email === "" ||
      mobile === "" ||
      message === ""
    ) {
      return "All fields are compulsory";
    } else if (name.length > 30) setErrMessage("Name is too long!");
    else if (!re.test(email)) {
      return "Enter correct e-mail address";
    } else if (mobile.length !== 10 || !pattern.test(mobile)) {
      return "Enter valid phone number";
    }
    return "";
  };

  return (
    <div className="mt-5">
      {/* {JSON.stringify(values)} */}
      <Container>
        <Row xs={1} md={2}>
          <Col>
            <center>
              <h3 className="mb-5">Find Us</h3>
            </center>
            <Card
              bg="light"
              text={"dark"}
              // style={{ width: "30rem" }}
              className="mb-5 mt-3 "
            >
              <center>
                <Card.Body>
                  <Card.Title>
                    <h4>Address</h4>
                  </Card.Title>
                  <Card.Text style={{ fontSize: "18px" }}>
                    ‘Abhivyakti’, B-308, Scheme 10-B,<br></br> Gopalpura Bye
                    Pass, Jaipur, Rajasthan – 302018
                  </Card.Text>
                  <hr />
                  <Card.Title>
                    {" "}
                    <h4>High Court Chamber</h4>
                  </Card.Title>
                  <Card.Text style={{ fontSize: "18px" }}>
                    Virendra Agrawal & Company (Law Firm)<br></br> Chamber No.
                    1-A, Old Building,<br></br> Rajasthan High Court, Jaipur
                    Bench
                  </Card.Text>
                  <hr />
                  <Card.Title>
                    <h4> Contact No.</h4>
                  </Card.Title>
                  <Card.Text style={{ fontSize: "18px" }}>
                    0141-2762423, +91-9414070423, +91-7737218033
                  </Card.Text>
                  <hr />
                  <Card.Title>
                    <h4>Email</h4>{" "}
                  </Card.Title>
                  <Card.Text style={{ fontSize: "18px" }}>
                    office@virendralaw.com
                  </Card.Text>
                </Card.Body>
              </center>
            </Card>
          </Col>
          <Col>
            <h3 className="mb-5">Get in Touch!</h3>
            <Form className="mb-5">
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>
                  Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Email address<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicMobile">
                <Form.Label>
                  Mobile No.<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={values.mobile}
                  placeholder="Enter Mobile No."
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Enter Message<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={values.message}
                  placeholder="Enter your message"
                  onChange={handleChange}
                />
              </Form.Group>
              {message ? (
                err ? (
                  <p className="center" style={{ color: "red" }}>
                    {errMessage}
                  </p>
                ) : (
                  <p className="center" style={{ color: "green" }}>
                    Thank you for getting in touch! We appreciate you contacting
                    us. One of our colleagues will get back in touch with you
                    soon. Have a great day!
                  </p>
                )
              ) : (
                <div></div>
              )}
              <center className="mb-2">
                <Button
                  variant=""
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: "gray",
                    color: "white",
                    margin: "5px",
                    boxShadow: "5px 5px 3px #a9a9a9",
                  }}
                >
                  {/* rgba(46, 46, 46, 0.62) */}
                  Submit
                </Button>
              </center>
            </Form>
          </Col>
        </Row>
        <Row className="mb-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.0412030334764!2d75.77850431499695!3d26.870431983145767!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5b4d7f72b0b%3A0xbcb8ca9e9009ddc6!2sVirendra%20Agrawal%20%26%20Company%20Law%20Firm!5e0!3m2!1sen!2sin!4v1631296668098!5m2!1sen!2sin"
            width="800"
            height="350"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            title={"2"}
          ></iframe>
        </Row>
      </Container>
    </div>
  );
}
