import React from "react";
import "./AboutUs.css";
import { Row, Col, Container } from "react-bootstrap";
const AboutUs = () => {
  return (
    <div className="m-2 mt-5 mb-5">
      <center>
        <h1>About the Firm</h1>
      </center>
      <div className="container mt-5 margin">
        <Row>
          <Col>
            <div id="founder1" className="margin-mob animate"></div>

            <Container className="center mt-3 animate">
              <span className="title-img">Mr. Virendra Agrawal</span>
            </Container>
          </Col>
          <Col>
            <p className="para" style={{textAlign: "justify"}}>
              <b>Virendra Agrawal & Company (VAC)</b> is a leading, full-service
              law firm based in Jaipur, Rajasthan, practising in a wide range of
              disciplines of law. The firm was established in the year 2007 by
              Mr. Virendra Agrawal under the tutelage and patronship of Freedom
              Fighter Late Shri. Prem Nidhi Agrawal, who inculcated his years of
              experience in forming an association of dedicated lawyers to
              provide optimal solutions in a practical and cost-efficient
              manner.
            </p>
            <p style={{textAlign: "justify"}}> 
              VAC started as a boutique law firm dealing mainly with various
              facets of Insurance law but with time has expanded into diverse
              areas of law and now provides assistance in a wide range of legal,
              regulatory, and advisory services, to a global clientele including
              leading Indian & Multinational Corporations, Public Sector
              Undertakings, Micro, Small & Medium Enterprises and individuals.
            </p>
            <p style={{textAlign: "justify"}}>
              Our working structure is an amalgamation of a traditional law firm
              and a barrister style chambers wherein members specialise in
              civil, criminal and commercial litigation before the Courts and
              Tribunals across the country as well as arbitration and other
              alternative dispute resolution process.
            </p>
            <p style={{textAlign: "justify"}}>
              The ethos on which the firm is established is{" "}
              <i>“Pacta Sunt Servanda” </i>
              which means that the promises made shall be fulfilled. The firm is
              committed to provide dedicated and result oriented solutions to
              the clients and assist them effectively with their issues. Keeping
              in view with the advancements of law, we endeavour to promote and
              efficiently utilise the alternative dispute resolution process for
              resolution of the disputes.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutUs;
