import React, { useEffect, useState } from "react";
import db from "../../firebase_conf/firebase_store";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import "./Knowledge.css";
const KnowledgeCenter = () => {
  const [urls, setUrls] = useState([]);
  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    fetchUrls();
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);
  const fetchUrls = async () => {
    setLoading(true);
    const response = db.collection("knowledge").orderBy("order", "desc");
    await response.get().then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data());
      const documents = [];
      const title = [];
      for (let d in data) {
        documents.push(data[d].drivelink);
        title.push(data[d].title);
      }
      setUrls(documents);
      setTitles(title);
    });
    // data.docs.forEach((item) => {
    //   setUrls([...urls, item.data().drivelink]);
    // });
    setLoading(false);
  };
  return (
    <>
      <h1 className="heading mt-5">Knowledge Center</h1>
      {loading ? (
        <div className="spinn">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {loader ? (
            <div style={{ textAlign: "center" }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div></div>
          )}
          <Container>
            <Row md={3} xs={1} className="mb-5">
              {urls.map((url, idx) => (
                <Col className="mt-5" key={idx}>
                  <Container>
                    <iframe
                      src={url}
                      height="400"
                      title={idx}
                    ></iframe>
                  </Container>
                  <Container className="center mt-3">
                      <span className="title-img2">{titles[idx]}</span>
                  </Container>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default KnowledgeCenter;
