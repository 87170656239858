import React, { useState } from "react";
import { Link } from "react-router-dom";
import companyLogo from "../images/companyLogo.png";
import "./Navbar.css";

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="background">
          <img
            src={companyLogo}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="company-logo"
          />
        </div>
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          {/* <div className="background  "></div> */}
          Virendra Agrawal & Company<br></br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          (Law Firm)
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/about-us"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/people" className="nav-links" onClick={closeMobileMenu}>
              Team
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/practice-areas"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Practice Areas
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/clients" className="nav-links" onClick={closeMobileMenu}>
              Clients
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/knowledge-center"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Resources
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/recruitment"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Recruitment
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
