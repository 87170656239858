import React from "react";
import { useState, useEffect } from "react";
import { Carousel, Container, Row, Col, Card } from "react-bootstrap";
import award1 from "../images/recognition/award1.jpeg";
import award2 from "../images/recognition/award2.jpg";
import award3 from "../images/recognition/award3.jpg";
import award4 from "../images/recognition/award4.jpg";
import award5 from "../images/recognition/award5.jpg";
import "./Recognition.css";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Recognition = () => {
  const { width } = useWindowDimensions();
  return width > 960 ? (
    <div style={{ width: "100%" }}>
      <Card
        className="padding p-5 mt-5"
        style={{ backgroundColor: "#f6f6f6", width: "100%" }}
      >
        <Container>
          <h1
            style={{ textAlign: "center", color: "#4f4f51" }}
            className="mb-5"
          >
            Recognition
          </h1>
          <div className="">
            <Carousel
              // style={{
              //   backgroundColor: "",
              //   padding: "5px",

              // }}
              variant="dark"
            >
              <Carousel.Item className="picture1">
                <Container>
                  <Row>
                    <Col>
                      <div style={{ height: "400px", width: "300px" }}>
                        <img
                          src={award1}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          className="d-block"
                          alt="award1"
                        ></img>
                      </div>
                    </Col>
                    <Col>
                      <div style={{ height: "400px", width: "300px" }}>
                        <img
                          src={award4}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          className="d-block"
                          alt="award2"
                        ></img>
                      </div>
                    </Col>
                    <Col>
                      <div style={{ height: "400px", width: "300px" }}>
                        <img
                          src={award5}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          className="d-block w-100"
                          alt="award3"
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Carousel.Item>
              <Carousel.Item className="picture1">
                <Container>
                  <Row>
                    <Col>
                      <div style={{ height: "400px", width: "300px" }}>
                        <img
                          src={award3}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          alt="award4"
                        ></img>
                      </div>
                    </Col>
                    <Col>
                      <div style={{ height: "400px", width: "550px" }}>
                        <img
                          src={award2}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          alt="award5"
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Carousel.Item>
              {/* <Carousel.Item className="picture">
          <Container style={{ justifyContent: "center" }}>
            <Row style={{ justifyContent: "center" }}>
              <Col>
                <div style={{ height: "500px", width: "700px" }}>
                  <img
                    src={award2}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      marginLeft: "200px",
                    }}
                  ></img>
                </div>
              </Col>
            </Row>
          </Container>
        </Carousel.Item> */}
            </Carousel>
          </div>
        </Container>
      </Card>
    </div>
  ) : (
    <Container>
      <Card
        className="padding pb-5"
        style={{ backgroundColor: "#f6f6f6", width: "100%" }}
      >
        <Container>
          <h1
            style={{ textAlign: "center", color: "#4f4f51" }}
            className="mb-5 mt-5"
          >
            Recognition
          </h1>

          <Carousel variant="dark">
            <Carousel.Item>
              <Container>
                {/* <div style={{ height: "350px", width: "200px" }}> */}
                <img
                  src={award1}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  // className="d-block"
                  alt="award1"
                ></img>
                {/* </div> */}
              </Container>
            </Carousel.Item>

            <Carousel.Item>
              <div style={{ height: "400px", width: "300px" }}>
                <img
                  src={award4}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  className="d-block"
                  alt="award3"
                ></img>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ height: "400px", width: "300px" }}>
                <img
                  src={award5}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  className="d-block"
                  alt="award4"
                ></img>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              {/* <div style={{ height: "400px", width: "300px" }}> */}
                <img
                  src={award3}
                  style={{
                    height: "80%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  className="d-block"
                  alt="award5"
                ></img>
              {/* </div> */}
            </Carousel.Item>

            <Carousel.Item>
              {/* <div style={{ height: "400px", width: "300px" }}> */}
                <img
                  src={award2}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  className="d-block"
                  alt="award2"
                ></img>
              {/* </div> */}
            </Carousel.Item>
          </Carousel>
        </Container>
      </Card>
    </Container>
  );
};

export default Recognition;
