import React from "react";
import "./Footer.css";
import { FiMail, FiPhoneIncoming } from "react-icons/fi";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import companyLogo from "../images/companyLogo.png";
const Footer = () => {
  return (
    <div className="tail">
      {/* <Disclaimer /> */}
      <Container className="mt-5">
        <Row className="align-items-center mt-5">
          <Col>
            <Container className="inline-block">
              <a href="tel:9414070423">
                <FiPhoneIncoming size={30} className="icon mt-1" />
              </a>
              <a
                href="https://www.facebook.com/vaclawfirm/"
                target="_blank"
                rel="noreferrer"
              >
                <center>
                  <AiFillFacebook size={36} className="icon" />
                </center>
              </a>
              <a
                href="https://www.linkedin.com/company/virendralaw/about/"
                target="_blank"
                rel="noreferrer"
              >
                <center>
                  <AiFillLinkedin size={37} className="icon" />
                </center>
              </a>
              <a
                href="mailto:virendra@virendralaw.com"
                target="_blank"
                rel="noreferrer"
              >
                <center>
                  <FiMail size={38} className="icon" />
                </center>
              </a>
            </Container>
            <Container className="inline-block mt-3">
              <Link to="/privacy-policy" style={{ color: "gray" }}>
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link to="/disclaimer" style={{ color: "gray" }}>
                Disclaimer
              </Link>
            </Container>
            {/* <Container className="inline-block mt-3">
              <div className="background">
                <img
                  src={companyLogo}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            </Container> */}
            {/* <Container>
              <div className="background">
                <img
                  src={companyLogo}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            </Container> */}
            <div className="op mt-3">
              <center>
                Virendra Agrawal & Company (Law Firm) <sup>&copy;</sup> 2021
              </center>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
