import React, { useEffect } from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./components/pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import People from "./components/pages/People";
import Recruitment from "./components/pages/Recruitment";
import Contact from "./components/pages/Contact";
import PracticeAreas from "./components/pages/PracticeAreas";
import AboutUs from "./components/pages/AboutUs";
import Clients from "./components/pages/Clients";
import Footer from "./components/Footer";
import KnowledgeCenter from "./components/pages/KnowledgeCenter";
import Disclaimer from "./components/Disclaimer";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
function App() {
  useEffect(() => {
    document.title = "Virendra Agrawal & Company | Best Law firm in Jaipur | India's best Law Firm";
  }, []);
  return (
    <Router>
      <Navbar />

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/people" component={People} />
        <Route path="/recruitment" component={Recruitment} />
        <Route path="/contact" component={Contact} />
        <Route path="/practice-areas" component={PracticeAreas} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/clients" component={Clients} />
        <Route path="/knowledge-center" component={KnowledgeCenter} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/disclaimer" exact component={Disclaimer} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
