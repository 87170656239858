import React from "react";
import "../../App.css";
import { Container, Col, Row } from "react-bootstrap";
import g1 from "../../images/photos/group1.jpg";
export default function Recruitment() {
  return (
    <Container className="mt-5 mb-5">
      <center>
        <h1 className="mb-5">Join Us</h1>
      </center>
      <Row xs={1} md={2}>
        <Col>
          <h3>Recruitment</h3>
          <p style={{ textAlign: "justify" }}>
            We at Virendra Agrawal & Co. are happy to welcome unparalleled legal
            professionals who aspire to associate with our Firm in its diverse
            practice areas. The unsurpassed culture of our Firm creates a
            platform for development of professional expertise in an environment
            which cherishes excellence and teamwork.
          </p>
          <p style={{ textAlign: "justify" }}>
            To learn more about available opportunities of associating with our
            Firm, please write to us at{" "}
            <a href="mailto:office@virendralaw.com">office@virendralaw.com</a>.
            In the covering mail please ensure you have mentioned the year of
            qualification and areas of experience/practice area, along with your
            curriculum vitae. In the subject line of your mail please mention
            “Application for Recruitment”.
          </p>
          <h3>Internship</h3>
          <p style={{ textAlign: "justify" }}>
            Our Internship Programme aims to provide our law students a glimpse
            into our daily routine, in a learning, friendly and stimulating
            environment. It also provides a platform to the students to
            demonstrate their potential and aims to give them an understanding
            of the practical nuances of law.
          </p>
          <p style={{ textAlign: "justify" }}>
            To learn more about available opportunities of internships at the
            VAC, please write to us at{" "}
            <a href="mailto:office@virendralaw.com">office@virendralaw.com</a>.
            In the covering mail please ensure you have attached your curriculum
            vitae and in the body have clearly specified the duration of
            internship. In the subject line please mention “Application for
            Internship”.
          </p>
        </Col>
        <Col
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div className="group-photo">
            <img
              src={g1}
              className="d-block w-100"
              style={{ height: "350px", width: "600px" }}
              alt="Recruitment"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
