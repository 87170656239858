import firebase from "firebase";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
// const app = firebase.initializeApp({
//   apiKey: "AIzaSyC0wclzo-pYg2Vg1wndwCTtc3_oebYb-2k",
//   authDomain: "lawfirm-2eb78.firebaseapp.com",
//   projectId: "lawfirm-2eb78",
//   storageBucket: "lawfirm-2eb78.appspot.com",
//   messagingSenderId: "1099428361369",
//   appId: "1:1099428361369:web:c2024075f8152a09ae7408",
//   measurementId: "G-TPSFYT06B2",
// });
const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();
export default db;
