import React from "react";
import "../../App.css";
import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Carousel,
  Card,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import g2 from "../../images/photos/group2.jpg";
import s1 from "../../images/photos/slide1.jpg";
import s2 from "../../images/photos/slide2.jpg";
import law3 from "../../images/img2.jpg";
import "./Home.css";
import Contact from "./Contact";
import Aos from "aos";
import "aos/dist/aos.css";
import Recognition from "../Recognition";

export default function Home() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    document.title = " Virendra Law Firm ";
    handleShow();
    Aos.init({ duration: 1200 });
  }, []);
  const Disclaimer = () => (
    <div className="container">
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        // dialogClassName={"text-center"}
      >
        <Modal.Header>
          <div className="center">
            {/* <Modal.Title> */}
            <div className="center large-text">DISCLAIMER</div>
            {/* </Modal.Title> */}
          </div>
        </Modal.Header>
        <Modal.Body>
          <p style={{textAlign: "justify"}}>
            {" "}
            The Bar Council of India does not permit advertisement or
            solicitation by advocates in any form or manner. By accessing this
            website, you acknowledge and confirm that you are seeking
            information relating to <b>Virendra Agrawal & Co.</b> of your own
            accord and that there has been no form of solicitation,
            advertisement or inducement by <b>Virendra Agrawal & Co.</b>or its
            members.
          </p>
          <p style={{textAlign: "justify"}}>
            The Bar Council of India does not permit advertisement or
            solicitation by advocates in any form or manner. By accessing this
            website, you acknowledge and confirm that you are seeking
            information relating to Virendra Agrawal & Co. of your own accord
            and that there has been no form of solicitation, advertisement or
            inducement by Virendra Agrawal & Co. or its members.
          </p>
        </Modal.Body>
        <div className="center">
          <Modal.Footer>
            <Button onClick={handleClose} variant="secondary">
              I Agree
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );

  const FromTheFounder2 = () => (
    <div data-aos="fade-up">
      <Card
        className="p-5 m-2 pb-5"
        style={{ backgroundColor: "#f6f6f6", justifyContent: "center" }}
      >
        <Card.Title>
          <div className="center left-top mb-5 mt-5">
            <h1 style={{ color: "#4f4f51" }}>From The Founder's Desk</h1>
          </div>
        </Card.Title>
        <Row xs="1" md="3" className="g-4 justify-content-md-center">
          <Col md="4">
            <div>
              <div id="founder" className="f2"></div>
            </div>
            <Container className="center mt-3 animate">
              <span className="title-img" style={{ color: "#4f4f51" }}>
                Freedom Fighter Late Shri. Prem Nidhi Agrawal
              </span>
            </Container>
          </Col>
          <Col md="4">
            <p className="mob-margin" style={{textAlign: "justify"}}>
              My journey began in 1984 when I started practising under the
              tutelage of Freedom Fighter Late Shri. Prem Nidhi Agrawal, who had
              an expertise in Election Petition laws, Constitutional laws and
              Civil laws, practising before the Supreme Court of India, High
              Court of Rajasthan, and various Subordinate Courts in Rajasthan,
              In 1990, I began my independent practice with a focus on Insurance
              and Civil Laws.
            </p>
            <p style={{textAlign: "justify"}}>
              {" "}
              In the year 2007, I established <b>Virendra Agrawal & Co.</b>, as
              a boutique law firm dealing with insurance law and representing
              clients mainly before the Hon’ble High Court of Rajasthan, Motor
              Accident Claim Tribunals, Consumer Forums and various other
              Subordinate Courts in Rajasthan. The idea behind establishing a
              law firm was to form an association of lawyers who could provide a
              one stop solution to cater to all the needs of our clients.
            </p>
            <p className="mob-margin-last" style={{textAlign: "justify"}}>
              Slowly and gradually, we have expanded our base and now we deal
              with a variety of avenues of law practice. In these 21 years, we
              have established our presence pan-India with a dedicated team of
              lawyers who have extensive experience in handling complex legal
              disputes in different jurisdictions throughout the nation.
              {/* <span style={{ fontSize: "25px" }}>’’</span> */}
            </p>
          </Col>
          <Col md={4}>
            <div id="founder"></div>

            <Container className="center mt-3 animate">
              <span className="title-img" style={{ color: "#4f4f51" }}>
                Mr. Virendra Agrawal
              </span>
            </Container>
          </Col>
        </Row>
      </Card>
    </div>
  );

  return (
    // <h1 className='home'>Home</h1>
    //style={{ height: "300px" }}
    <>
      <Disclaimer />

      <div className="cont1 back-color">
        {/* <Container> */}
        <Carousel fade variant="">
          <Carousel.Item className="picture">
            {/* <Container> */}
            <img
              className="d-block w-100 picture"
              src={g2}
              alt="Second slide"
            />
            {/* </Container> */}
          </Carousel.Item>
          <Carousel.Item className="picture">
            <img className="d-block w-100 picture" src={s1} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item className="picture">
            <img className="d-block w-100 picture" src={s2} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item className="picture">
            <img
              className="d-block w-100 picture"
              src={law3}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
        {/* </Container> */}
        {/* <Card className="padding m-2 pb-5 mb-5 mt-5">
          <Card.Title>
            <center>
              <h1>About Us</h1>
            </center>
          </Card.Title>
          <Row xs="1" md="2">
            <Col md="6">
              <Row>
                <div id="founder"></div>
              </Row>
            </Col>
            <Col md="6">
              <p className="mt-5">
                <b>Virendra Agrawal & Company (VAC)</b> is a leading,
                full-service law firm based in Jaipur, Rajasthan, practicing in
                a wide range of disciplines. The firm was established in the
                year 2007 by Mr. Virendra Agrawal under the tutelage and
                patronship of Freedom Fighter Late. Shri Prem Nidhi Agrawal, who
                inculcated their years of experience in forming an association
                of dedicated lawyers to provide optimal solutions in a practical
                and cost-efficient manner.
              </p>
              <p>
                VAC started as a boutique law firm dealing mainly with various
                facets of Insurance law but with time has expanded in diverse
                areas of law and now provides assistance to clients in a wide
                range of legal, regulatory, and advisory services to a global
                clientele including leading Indian & Multinational Corporations,
                Public Sector Undertakings, Micro, Small & Medium Enterprises
                and individuals.
              </p>
            </Col>
          </Row>
        </Card> */}
        <div className="mb-5">
          <FromTheFounder2 />
        </div>
        <hr />
        <Container className="">
          <div data-aos="flip-up" className="mb-5">
            <Recognition />
          </div>
        </Container>
        <div data-aos="zoom-in-up" className="mt-5 mb-5">
          <Contact />
        </div>
      </div>
    </>
  );
}
