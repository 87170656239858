import React from "react";
import { Container } from "react-bootstrap";
const Disclaimer = () => (
  <Container className="mb-5">
    <h1 className="mt-5 mb-5 center">Disclaimer</h1>
    <p style={{ fontSize: "20px" }}>
      {" "}
      The Bar Council of India does not permit advertisement or solicitation by
      advocates in any form or manner. By accessing this website, you
      acknowledge and confirm that you are seeking information relating to{" "}
      <b>Virendra Agrawal & Co.</b> of your own accord and that there has been
      no form of solicitation, advertisement or inducement by{" "}
      <b>Virendra Agrawal & Co.</b>or its members.
    </p>
    <p className="mb-5" style={{ fontSize: "20px" }}>
      The Bar Council of India does not permit advertisement or solicitation by
      advocates in any form or manner. By accessing this website, you
      acknowledge and confirm that you are seeking information relating to
      Virendra Agrawal & Co. of your own accord and that there has been no form
      of solicitation, advertisement or inducement by Virendra Agrawal & Co. or
      its members.
    </p>
  </Container>
);

export default Disclaimer;
