import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import acko from "../../images/clientlogos/acko.png";
import axa from "../../images/clientlogos/axa.png";
import barmer from "../../images/clientlogos/barmer.jpg";
import bajaj from "../../images/clientlogos/bajaj.jpg";
import digit from "../../images/clientlogos/digit.png";
import future from "../../images/clientlogos/future.png";
import mahindra from "../../images/clientlogos/mahindra.jpg";
import chola from "../../images/clientlogos/chola.png";
import hdfc from "../../images/clientlogos/hdfc.jpg";
import himachal from "../../images/clientlogos/HImachal.png";
import icici from "../../images/clientlogos/ICICI-Lombard.jpg";
import iffco from "../../images/clientlogos/IFFCO.png";
import liberty from "../../images/clientlogos/Liberty.jpg";
import reliance from "../../images/clientlogos/reliance.png";
import royalsunda from "../../images/clientlogos/royalsundaram.jpeg";
import rsrtc from "../../images/clientlogos/RSRTC.jpg";
import sbi from "../../images/clientlogos/sbi.png";
import shriram from "../../images/clientlogos/Shriram.png";
import oriental from "../../images/clientlogos/oriental.png";
import uniOfRaj from "../../images/clientlogos/uniOfRaj.jpg";
import united from "../../images/clientlogos/united.png";
import universalSompo from "../../images/clientlogos/UniversalSompo.jpg";
import landmark from "../../images/clientlogos/landmarkInsurance.jpg";
import magma from "../../images/clientlogos/magma.jpg";
import phirki from "../../images/clientlogos/phirki.jpeg";
const images = [
  acko,
  axa,
  shriram,
  bajaj,
  iffco,
  future,
  mahindra,
  phirki,
  hdfc,
  oriental,
  sbi,
  icici,
  magma,
  liberty,
  reliance,
  royalsunda,
  rsrtc,

  chola,
  digit,

  himachal,
  uniOfRaj,
  united,
  universalSompo,
  landmark,
  barmer,
];
const Clients = () => {
  return (
    <div className="mt-5 mb-5">
      <center>
        <h1>Our Clients</h1>
        {/* <h5 className="opacity-50 mb-5">A snapshot of who we’ve worked with</h5> */}
      </center>
      <Container className="mt-5">
        <Row md={5} xs={2}>
          {images.map((image) => (
            <Col>
              <img
                src={image}
                style={{ height: "70px", width: "170px" }}
                className="mb-4"
                alt={image.name}
              ></img>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Clients;
