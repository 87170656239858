import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Card, Container, Accordion } from "react-bootstrap";
import "./PracticeAreas.css";
const practiceAreas = [
  "ALTERNATE DISPUTE RESOLUTION",
  "AVIATION",
  "COMMERCIAL CONTRACTS",
  "CONSUMER",
  "CRIMINAL LITIGATION",
  "E-COMMERCE TRANSACTIONS",
  "EDUCATION",
  "GENERAL LITIGATION",
  "INFORMATION TECHONOLGY",
  "INSOLVENCY AND BANKRUPTCY",
  "INSURANCE",
  "INTELLECTUAL PROPERTY RIGHTS",
  "LABOUR AND EMPLOYMENT",
  "MATRIMONIAL",
  "MEDIA & ENTERTAINMENT",
  "MICRO SMALL MEDIUM ENTERPRISES [MSME]",
  "OIL, GAS & PETROLEUM",
  "PRIVATE CLIENT PRACTICE",
  "REAL ESTATE",
  "START UP ADVISORY",
  "SERVICE LAW",
  "WHITE COLLAR CRIME",
];
// const practiceAreasDesc = [
//   "As the global and domestic economy grows and becomes interconnected, it is leading to tighter regulatory oversight, higher accountability standards and intense competition. The interplay of these dynamics creates challenges that can test the resolve and slow down the progress of organisations, creating higher costs and liabilities, requiring that disputes are best avoided and addressed effectively and efficiently, should they arise. Our emphasis is on aligning litigation strategy with the commercial interests of our clients. We understand time is of the essence for most strategic litigation, and so our objective is to provide effective remedy efficiently. We bring in significant domain knowledge and expertise. ",
//   "Our work spans the spectrum of financial products, including project finance, leveraged finance, distressed debt transactions, debt restructuring, acquisition finance, syndicated lending and structured products. Our lawyers have a clear understanding of the expectations and requirements of both sides to a financing transaction and provide tailored advice to each client’s needs.",
//   "At VAC, we offer comprehensive consultation on all matters related to the e-commerce industry, and for this, we are regarded as market leaders in the field. Our services cover the entire gamut of legal needs in the e-commerce and retail ecosystems. Our nuanced and technical understanding of both, the regulatory framework and our clients’ businesses give us the unique advantage of being able to advise clients on both the corporate and operational sides of any enterprise. Our clients include private equity holders and venture capitalists looking to invest in e-commerce companies. We also deal with e-commerce start-ups and companies looking to shift to the next phase of growth. Apart from this, we provide legal services to Indian conglomerates looking to establish an e-commerce presence, as well as technology and logistics companies and investment banks.",
//   "The firm has extensive experience in representing clients in all type of Criminal matters at PAN India level, in all courts including Supreme Court of India, High Courts and District Courts. The team comprises of efficient professionals and qualified advocates, who have excellent skills to handle cases of criminal and quasi criminal nature including defamation, white collar crimes, economic offences, insider trading, corporate frauds, market manipulation etc. The team is well equipped to handle critical aspects and consequences that form a part of criminal litigation.",

//   "We advise companies and financial institutions on a wide spectrum of complex commercial contract issues. Working with our clients on such issues contributes greatly to our understanding of their businesses, which in turn enhances our services on transactional or litigation matters.",
//   "VAC has also established an extensive Consumer Litigation Practice backed by a team of exceptionally experienced and talented lawyers having expertise in the crucial Consumer Law Sector. The consumer law practice has seen huge evolution with the service providers put in place to be more accountable and responsible for their commitments. Consequently, the consumers are at a front foot today with getting their grievances redressed in a time bound manner.",
//   "At VAC our market-leading insurance practice provides the full spectrum of legal services to insurers, reinsurers, intermediaries, investment banks, investors and policyholders. We have in-depth knowledge and understanding of the legal, regulatory and commercial issues faced by the insurance industry in India and regularly act on the cutting edge of Indian insurance work. Our team regularly advises on complex M&A and PE transactions in the insurance sector, as part of growth, market entry or consolidation strategies (or disposals as investors or partners exiting their investments), regulatory matters, insurance registrations, distribution channels and networks, fund raising (public offerings and debt issuances), restructuring, litigation and digital platforms.",
//   "In the Aviation space, VAC has advised clients on various legal issues arising out of sale and lease as well as other financing options of aircrafts, equipment and parts thereof and MRO and Ground Handling services. VAC has considerable experience and domain knowledge in the Aviation sector.",
//   "VAC has a dedicated education practice with extensive transactional and advisory credentials. Our team has advised leading private equity firms, strategic investors, international education groups, top-tier international and Indian universities. Our deep sectoral and regulatory experience across higher education, ed-tech and online education combined with our team’s vast experience in mergers and acquisitions, enables us to anticipate and respond to sectoral issues",

//   "The firm has a cutting edge practical experience in conducting both private and commercial litigation before diverse fora, including Supreme Court of India, High Courts and District Courts. The firm’s litigation practice encompasses various fields of law including Constitutional law, Commercial law, Intellectual Property & Cyberlaws, Criminal laws, Employment laws, Real Estate & Land laws, Maritime laws and Family laws, besides other practice areas. The firm also has an established network of associates in several jurisdictions, who actively represent firm’s clients conducting litigation matters across various jurisdictions. Our lawyers have successfully represented clients in matters across various industries/sectors including Information Technology, Education, Manufacturing, Export, Hospitality, and Healthcare. The firm also has practical experience in conducting cybercrime matters, including hacking and data theft cases and/or Intellectual Property infringements before various fora, including before authorities appointed under the Information Technology Act, 2000.",

//   <p>
//     The core value of the IPR team lies in the convergence of technical and
//     legal knowledge with regular in-house training programmes leading the team
//     to gain expertise in the following areas:{" "}
//     <ul>
//       {" "}
//       <li>Patent Laws </li>
//       <li> Trademark Laws</li>
//       <li>Copyright Laws</li>
//       <li> Industrial Designs</li> <li> Geographical Indications</li>{" "}
//       <li>Plant Varieties</li>
//       <li> Layout Designs of Integrated Circuits</li>{" "}
//       <li>Information Technology </li>
//       <li>Cyber Laws </li>
//       <li> Entertainment/Media Laws & Litigation</li>
//     </ul>
//   </p>,
//   "Labour and employment is one of the key practice areas of VAC and apart from advising on labour/employment issues as part of M&A, business transfer and reorganisation transactions, Firm regularly advises clients on stand-alone labour/employment matters. Experience in this area ranges from assisting clients in the preparation of service rules, structuring of compensation packages, drafting of employment contracts, non-compete and confidentiality agreements and termination notices to negotiating collective bargaining contracts, advising on termination, retrenchment, lay-offs & strikes and seeking permissions and registrations under various labour/employment-related laws and regulations. The Firm provides assistance to manufacturing companies employing a large number of factory workers covered by various labour/employment related statutes as well as companies in the service sector including software and BPO companies.",
//   "At VAC we have legal as well as technical expertise in providing advice and solutions for protection against theft of images, theft & secrecy laws, graphics, website designs and content and copyright infringement, domain disputes and domain name recovery, software copyright infringement, internet piracy, cyber-crimes, data protection, information security and secrecy, digital rights management, regulatory and legal compliance, civil and criminal prosecution. Additionally, the team also acquaints the clients with privacy laws in India and the different ways to ensure the confidentiality of data transferred across borders including General Data Protection Regulation (GDPR) and E-commerce.",
//   "VAC has long-standing experience in the international oil and gas industry. Since the very early days of the Firm, oil and gas has been vital to our practice. Through advising in hundreds of transactions, projects and other oil and gas developments, Miranda has amassed a wealth of knowledge in this industry that is unsurpassed by any other firm in the jurisdictions we cover. The breadth of our practice and our experience with a diversity of jurisdictions and oil and gas companies enables us to provide clients with the most sound and effective advice and representation.",
//   "Technology, Media and Entertainment laws is one of the core specialization areas of the firm. We specialize in advising on Information Technology law compliances, data protection , privacy, Copyright and trademark laws and licensing and assignment of Intellectual property assets including literary work, musical work or movies. Our lawyers advise on drafting of copyright contracts and licensing of film/ music album/ television program rights between different stakeholders. We also advise broadcasting corporations and copyright societies, authors, producers and artists , music composers or sound recording companies on protecting their rights under Indian laws and represent clients in diverse litigation before Indian courts.",
//   <p>
//     One of the key areas of law practice of our firm is Matrimonial and Family
//     laws. The firm advises its foreign and domestic clients on all aspects of
//     matrimonial and family laws applicable in India. The firm further actively
//     represents clients before various legal forums including family courts,
//     district courts, High Courts in India and the Supreme Court, National Human
//     Rights Commission, YWCA, National Commission of Women, other Women’s cell /
//     organizations.
//     <ul>
//       <li>Registration of marriages</li> <li> Divorce</li>
//       <li> Dowry demand complaint cases</li>
//       <li>Domestic violence</li> <li>Police protection </li>
//       <li> Maintenance</li> <li>Restitution of conjugal rights</li>
//       <li> Child marriages </li>
//       <li> Adoption</li> <li> Guardianship</li> <li> Personal laws </li>
//       <li> Muslim laws</li>
//       <li>Inheritance & succession</li> <li> Probate and wills</li>{" "}
//       <li>Partition</li>
//     </ul>
//   </p>,
//   "Our Private Client practice is a bespoke service offering that addresses the many complex challenges that multi-generational business families, successful entrepreneurs, celebrities and senior executives face in relation to succession planning, tax planning, philanthropy issues, and their desire to remain strong in the wake of globalisation and regulatory compliance. We also work with industry players such as family offices, wealth management companies, trustee companies and others, in relation to how they function and how they can assist clients in their estate planning.",
//   "The growingly complex framework of criminal laws and rising prosecutions associated with white collar crime are increasing risks associated with running ever-complicated businesses in India. We advise our clients on all aspects of such criminal investigations and proceedings, whether at an institutional or individual level. Our white collar crimes practice division advises numerous domestic and international clients. We help them conduct internal audits and create appropriate risk mitigation strategies, defence in investigations, criminal trials and appellate proceedings before courts, corporate investigations and general advisory in relation to wrongdoings attributed to promoters and other employees, proceedings to protect personal liberty, blacklisting proceedings and cancellation of contracts.",
//   "VAC leverages its knowledge base in the field of IP, Companies Act, and Commercial advisory to comprehensively advice startups on business strategy, which includes incorporation services, drafting commercial deals, hiring employees and high value executives, offering sweat-equity to employees, framing employee stock option plans, negotiating co-founder agreements, selling of shares, pre-investment negotiations and many more.",
//   "VAC represents various individuals and governmental departments, private corporations and NGOs in matters arising out of promotion, termination, pension, retirement, selection gratuity etc. before various judicial and quasi-judicial authorities including Supreme Court, High Courts and the Administrative Tribunals.",
//   "The Indian real estate industry is constantly evolving and the regulatory framework is being put in place to make it more accountable and responsive to the needs of the stakeholders and to contribute to the economic growth of the country. Consequently, structuring real estate transactions has become more and more complex and sophisticated by the day and it requires a great understanding of relevant regulations, sector specific government policy framework and in-depth knowledge of the market to respond to complex situations faced by our clients and provide legally and regulatory compliant, innovative, pragmatic and speedy solutions which are cost effective, practical and commercially justifiable, as expected in today’s highly competitive and rapidly changing real estate environment. Our team has a great understanding of local laws and requirements as well title verification, development requirements, FAR regulations, land ceiling, stamp duty and registration etc.",
//   "The Team is an amalgamation of domain specialised lawyers who are dedicated to handle issues arising in the MSME- Micro, Small & Medium Enterprises sector in India. The firm advises clients in the MSME sector from incorporation of entities, regulatory and compliances with the Registrar of Companies as well the MSME registration with the Ministry of Micro, Small & Medium Enterprises, Government of India. We also advise with regular compliances in the sector as well as Taxation related aspects impacting the sector.",
// ];
const PracticeAreas = () => {
  return (
    <>
      <h1 className="center mt-5 mb-5">Our Expertise</h1>
      <Container>
        <Row xs={1} md={1} className="justify-content-md-center p-3">
          <Col>
            <p>
              As a full service law firm, we offer a full spectrum of legal
              services. Our dynamic team provides experienced counsel on a
              diverse range of practice areas. Our team regularly appears before
              the Hon’ble Supreme Court of India, various High Courts, District
              Courts and specialised tribunals/forums such as Consumer Forums,
              Motor Accident Claims Tribunal, NGT, DRT, DRAT, RERA, NCLT,
              CAT/RAT and Arbitral Tribunals in diverse range of matters such
              as:
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="mb-5">
        <Row xs={1} md={1}>
          <Accordion defaultActiveKey="1">
            {Array.from({ length: practiceAreas.length }).map((_, idx) => (
              <Col key={idx}>
                {/* <div>
                  <Accordion.Item eventKey={idx}>
                    <Accordion.Header>
                      <Card
                        className={
                          idx % 2 === 0 ? "accordion-back" : "accordion-normal"
                        }
                      >
                        {practiceAreas[idx]}
                      </Card>
                    </Accordion.Header>
                  </Accordion.Item>
                </div> */}
                <Card
                  style={{ padding: "5px", margin: "10px" }}
                  className={idx % 2 === 0 ? "darkMode" : "lightMode"}
                >
                  <Card.Body>
                    <h5 className={idx % 2 === 0 ? "darkMode" : "lightMode"}>
                      {practiceAreas[idx]}
                    </h5>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Accordion>
        </Row>
      </Container>
    </>
  );
};
export default PracticeAreas;
