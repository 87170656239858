import React, { useState } from "react";
import "../../App.css";
import { Card, Row, Col, Container } from "react-bootstrap";
import "./People.css";
import aakarsh from "../../images/photos/aakarsh.jpeg";
import prateek from "../../images/photos/prateek.jpeg";
import prakhar from "../../images/photos/prakhar.jpeg";
import manikant from "../../images/photos/manikant.jpeg";
import chelsi from "../../images/photos/chelsi.jpeg";
import atul from "../../images/photos/atul.jpeg";
import santosh from "../../images/photos/santosh.jpeg";

const names = [
  "Dr. Prateek Sharma",
  "Santosh Kumar Soni",
  "Prakhar Agrawal",
  "Chelsi Gangwal",
  "Aakarsh Gothwal",
  "Manikant Choudhary",
];
const NamesDesc = ({ idx }) => {
  const [show, setShow] = useState([false, false, false, false, false, false]);

  const handleShow = () => {
    const st = [false, false, false, false, false, false];
    st[idx] = true;
    console.log(st);
    setShow(st);
    console.log(show);
  };

  const nameDesc = [
    <div style={{ textAlign: "justify" }}>
      Dr. Sharma studied law at the New Law College, Bhartiya Vidyapeeth
      University, Pune and obtained his L.LM-M.B.A from Mohan Lal Sukhadia
      University in 2011.
      <span className="read-or-hide" onClick={handleShow}>
        {show[idx] ? "" : "...read more"}
      </span>{" "}
      {show[idx] ? (
        <>
          <span>Thereafter he completed his Ph.D in Law in the year 2019.</span>
          <br></br>
          <br></br>
          <p style={{ textAlign: "justify" }}>
            {" "}
            He enrolled in the rolls of the Bar Council of Rajasthan in 2008 and
            has been appearing regularly before the Supreme Court of India,
            Rajasthan High Court and Subordinate Courts throughout the State of
            Rajasthan, including various specialised tribunals such as Consumer
            Forums, NGT, DRT and Motor Accident Claims Tribunal.
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Dr. Sharma has handled complex litigation for various corporate
            houses and private clientele and is highly regarded for his
            versatility as a corporate and commercial trial and appellate
            lawyer.
          </p>
          <p>
            {" "}
            He has an astute experience in cases relating to matrimonial and
            succession matters, insurance law and alternative dispute
            resolution. He regularly instructs key public sector undertakings on
            their litigation strategies.
          </p>
        </>
      ) : (
        ""
      )}
    </div>,
    <div style={{ textAlign: "justify" }}>
      Mr. Santosh Kumar Soni completed his B.A and LL.B from University of
      Rajasthan. He enrolled in the roles of the Bar Council of Rajasthan in
      2015{" "}
      <span className="read-or-hide" onClick={handleShow}>
        {show[idx] ? "" : "...read more"}
      </span>{" "}
      {show[idx] ? (
        <>
          and has an experience of about 6 years as a paralegal before his
          enrolment. He appears before the Hon’ble High Court of Rajasthan,
          various Subordinate Courts in Rajasthan, Central and Rajasthan
          Administrative Tribunal and DRT/DRAT. His areas of practice includes
          insurance law, criminal law, service law and civil law.
          <br></br>
          <br></br>
          <p style={{ textAlign: "justify" }}>
            He is an active member of the Rajasthan High Court Bar Association
            and has provided valuable assistance in their various initiatives.
          </p>
        </>
      ) : (
        ""
      )}
    </div>,
    <div style={{ textAlign: "justify" }}>
      Mr. Prakhar Agrawal is an alumnus of the Five Years Integrated B.L.S LL.B
      Course from Government Law College, Mumbai. He also holds a LL.M in
      Criminal
      <span className="read-or-hide" onClick={handleShow}>
        {show[idx] ? "" : "...read more"}
      </span>{" "}
      {show[idx] ? (
        <>
          & Security Laws, in addition to Diploma in Cyber Laws, Sports Laws and
          Drafting, Negotiation and Enforcement of Contracts. He is an Associate
          Member of the Chartered Institute of Arbitrators (U.K).
          <br></br>
          <br></br>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Prakhar regularly appears before the High Court of Rajasthan,
            Commercial Courts, Consumer Forums and various Specialised
            Tribunals, including the MSME Council, RERA, NCLT, CAT/RAT and the
            Arbitral Tribunals. His areas of practice include arbitration law,
            sports law, consumer law, commercial law, constitutional law,
            insurance law and service law.
          </p>
          <p style={{ textAlign: "justify" }}>
            He is also an active member of the Young SIAC, Young MCIA, Young
            ICCA, ICC-YAF, YIAG-LCIA and AIAC-YPG.
          </p>
        </>
      ) : (
        ""
      )}
    </div>,

    <div style={{ textAlign: "justify" }}>
      Ms. Chelsi Gangwal graduated from the 5 year B.A LL,B course from the
      University of Rajasthan. She is enrolled in the roles of the bar council
      of Rajasthan
      <span className="read-or-hide" onClick={handleShow}>
        {show[idx] ? "" : "...read more"}
      </span>{" "}
      {show[idx]
        ? `
      and appears before the Hon’ble High Court of Rajasthan, various
      Subordinate Courts in Rajasthan and Consumer Forums. Her areas of practice
      include insurance law, consumer laws and labour laws.`
        : ""}
    </div>,
    <div style={{ textAlign: "justify" }}>
      Mr Aakarsh Gothwal completed his B.B.A LL.B (Hons.) and enrolled in the
      roles of the Bar Council of Rajasthan in the year 2020. He appears before
      the Hon’ble{" "}
      <span className="read-or-hide" onClick={handleShow}>
        {show[idx] ? "" : "...read more"}
      </span>{" "}
      {show[idx]
        ? `
      High Court of Rajasthan and specialized forums such as NCLT/NCLAT, RERA,
      and IPAB. His area of practice includes general litigation, intellectual
      property law, constitutional laws and real estate Law.`
        : ""}
    </div>,
    <div style={{ textAlign: "justify" }}>
      Mr Manikant Mavaliya completed his B.A LL.B from the University of
      Rajasthan. He enrolled in the roles of the Bar Council of Rajasthan in the
      year 2019.
      <span className="read-or-hide" onClick={handleShow}>
        {show[idx] ? "" : "...read more"}
      </span>{" "}
      {show[idx] ? (
        <>
          He appears before the Hon’ble High Court of Rajasthan and various
          Districts Courts in Rajasthan.
          <br></br>
          <br></br>
          <p>
            He also appears in various quasi-judicial forums relating to
            licensing and compliances of various distillery distribution units.
            His area of practice includes criminal litigation pertaining to
            bail, narcotics and family matters.
          </p>
        </>
      ) : (
        ""
      )}
    </div>,
  ];
  return nameDesc[idx];
};
const images = [prateek, santosh, prakhar, chelsi, aakarsh, manikant];
const nameEmail = [
  "prateek@virendralaw.com",
  "santosh@virendralaw.com",
  "prakhar@virendralaw.com",
  "chelsi@virendralaw.com",
  "aakarsh@virendralaw.com",
  "manikant@virendralaw.com",
];

const People = () => {
  const [show, handleShow] = useState(false);
  return (
    <Col className="mb-5">
      <center>
        <h1 className="mt-5 mb-5">Our Team</h1>
      </center>
      <Row xs={1} md={1} className="g-4 m-2 justify-content-md-center">
        <Col key="main">
          <Card>
            {/* <Card.Img variant="top" src={avatar} alt="CardImage" /> */}
            <Row>
              <Col md={3}>
                <div>
                  <div id="founder2" className="mt-5"></div>
                </div>
                <Container className="center mt-3">
                  <span className="title-img">Mr. Virendra Agrawal</span>
                  <br></br>
                  <span className="title-img2">
                    Founder and Managing Partner
                  </span>
                </Container>
              </Col>
              <Col md={9} className="mt-5 ml-3">
                <Container className="desk">
                  <p style={{ textAlign: "justify" }}>
                    Virendra Agrawal is a seasoned lawyer with over 36 years of
                    experience in representing clients in litigation and
                    arbitration. His expertise lies in Insurance, consumer,
                    civil and commercial litigation. He represents most of the
                    general insurance companies and large to mid-size Indian
                    businesses. He has been involved in some of the prominent
                    and landmark cases in India and has been a contributor to
                    the development of jurisprudence in India through his
                    representation on such matters.
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    Mr. Agrawal has and continues to represent clients in
                    matters before the Supreme Court of India, High Court of
                    Rajasthan, National, State and District Consumer Forums,
                    various arbitral tribunals and mediation and conciliation
                    forums. He has extensive experience in Third party motor
                    insurance claims. His invaluable contribution on matters
                    related to Motor insurance claims has made him prominent in
                    this field. He also advises Insurance Companies for handling
                    the claims and assisting them in formulating litigation
                    strategy.
                  </p>
                  {/* <br></br> */}
                  <p style={{ textAlign: "justify" }}>
                    Mr. Agrawal is a prominent member of the Bar Association,
                    having being elected as the General Secretary of the
                    Rajasthan High Court Bar Association in such 7 years of
                    practice. His opinions and views are widely sought by
                    multinational and Indian insurance companies for day to day
                    running of business. Recognized for his deep understanding
                    of the field of law and business awareness, he is a client
                    choice lawyer and often called upon to devise litigation &
                    arbitration strategies and counselling on extensive matters
                    involving prominent business houses and organizations. Lok
                    Adalats & Mediation.
                  </p>
                  <br></br>
                  {""}
                  <hr></hr>
                  <center>
                    <p>
                      <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                        E-mail: virendra@virendralaw.com
                      </div>
                    </p>
                  </center>
                </Container>
                <Container className="mob">
                  <div style={{ textAlign: "justify" }}>
                    Virendra Agrawal is a seasoned lawyer with over 36 years of
                    experience in representing clients in litigation and
                    arbitration. His expertise lies in Insurance, consumer,
                    civil and commercial litigation.
                    <span className="read-or-hide" onClick={handleShow}>
                      {show ? "" : "...read more"}
                    </span>{" "}
                    {show ? (
                      <>
                        He represents most of the general insurance companies
                        and large to mid-size Indian businesses. He has been
                        involved in some of the prominent and landmark cases in
                        India and has been a contributor to the development of
                        jurisprudence in India through his representation on
                        such matters.
                        <br></br>
                        <br></br>
                        <p style={{ textAlign: "justify" }}>
                          Mr. Agrawal has and continues to represent clients in
                          matters before the Supreme Court of India, High Court
                          of Rajasthan, National, State and District Consumer
                          Forums, various arbitral tribunals and mediation and
                          conciliation forums. He has extensive experience in
                          Third party motor insurance claims. His invaluable
                          contribution on matters related to Motor insurance
                          claims has made him prominent in this field. He also
                          advises Insurance Companies for handling the claims
                          and assisting them in formulating litigation strategy.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          Mr. Agrawal is a prominent member of the Bar
                          Association, having being elected as the General
                          Secretary of the Rajasthan High Court Bar Association
                          in such 7 years of practice. His opinions and views
                          are widely sought by multinational and Indian
                          insurance companies for day to day running of
                          business. Recognized for his deep understanding of the
                          field of law and business awareness, he is a client
                          choice lawyer and often called upon to devise
                          litigation & arbitration strategies and counselling on
                          extensive matters involving prominent business houses
                          and organizations. Lok Adalats & Mediation.
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                    <br></br>
                    {""}
                    <hr></hr>
                    <center>
                      <p>
                        <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                          E-mail: virendra@virendralaw.com
                        </div>
                      </p>
                    </center>
                  </div>
                </Container>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row xs={1} md={3} className="g-4 m-2">
        {Array.from({ length: names.length }).map((_, idx) => (
          <Col key={idx}>
            <Card>
              {/* <Card.Img variant="top" src={avatar} alt="CardImage" /> */}
              <div className="center">
                <img id="avatar" src={images[idx]} alt={names[idx]}></img>
              </div>
              <Card.Body>
                {/* <Card.Text> */}
                <Container className="center">
                  <span className="title-img">{names[idx]}</span>
                  <br></br>
                  <span className="title-img2">
                    {idx !== 6 ? "Advocate" : "Office Coordinator"}
                  </span>
                </Container>
                <br></br>
                <NamesDesc idx={idx} />
                <hr></hr>
                <center>
                  <p>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                      E-mail: {nameEmail[idx]}
                    </div>
                  </p>
                </center>
              </Card.Body>
            </Card>
          </Col>
        ))}
        <Col md={{ span: 4, offset: 4 }}>
          <Card>
            {/* <Card.Img variant="top" src={avatar} alt="CardImage" /> */}
            <div className="center">
              <img id="avatar" src={atul} alt={"Atul Khandelwal"}></img>
            </div>
            <Card.Body>
              {/* <Card.Text> */}
              <Container className="center">
                <span className="title-img">{"Atul Khandelwal"}</span>
                <br></br>
                <span className="title-img2">{"Office Coordinator"}</span>
              </Container>
              <br></br>
              {""}
              <hr></hr>
              <center>
                <p>
                  <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                    E-mail: atul@virendralaw.com
                  </div>
                </p>
              </center>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default People;
